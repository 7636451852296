import _ from 'lodash';

import { FwStore } from 'components/base';
import { getDocData } from 'components/doc//helpers';
import { Document } from 'core/model';

const getEntityExtended = (entityDoc: Document, store: FwStore) => {
  const newEntity = {};

  const { rules } = entityDoc.template;
  const data = entityDoc.data ? JSON.parse(entityDoc.data) : {};

  // converts the entityDoc into a simplified structure (a key with a value of type string)
  _.forEach(data, function (value, key) {
    if (_.isArray(value)) {
      const newValue = _.map(value, (v) =>
        // return stringify value if it's an object and return the value if it's a string
        _.isObject(v) ? JSON.stringify(v) : v
      );

      // update form to get string from array
      newEntity[key] = _.join(newValue, '|');
    } else if (!_.isArray(value) && _.isObject(value)) {
      if (!_.isEmpty(value)) {
        // loop to check if there is an object inside an object
        _.forEach(value, function (subValue, subKey) {
          if (!_.isArray(subValue) && _.isObject(subValue)) {
            if (!_.isEmpty(subValue)) {
              // update form to get string from object
              newEntity[key + '.' + subKey] = JSON.stringify(subValue);
            }
          } else if (subValue) {
            newEntity[key + '.' + subKey] = subValue;
          }
        });
      }
    } else {
      newEntity[key] = !_.isNil(value)
        ? _.isString(value)
          ? value
          : `${value}`
        : '';
    }
  });

  return rules && rules.length > 0
    ? getDocData(entityDoc, store, newEntity)
    : newEntity;
};

export default getEntityExtended;
