import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FwButton,
  FwGrid,
  FwMessage,
  FwSegment,
  FwParagraph,
  FwSpinner,
} from 'components/base';

const About: FC<any> = ({
  generatedKey,
  info,
  loadingInfo,
  loadingKey,
  onGenerateKey,
}) => {
  const { t } = useTranslation();

  return (
    <FwGrid>
      <FwSegment
        as={FwGrid}
        leftIcon="RiListSettingsLine"
        name={t('common|Database infos')}
      >
        <FwParagraph heading>{'ConnectionStrings :'}</FwParagraph>
        {loadingInfo ? <FwSpinner /> : <FwParagraph>{info}</FwParagraph>}
      </FwSegment>
      <FwSegment
        as={FwGrid}
        leftIcon="RiKey2Fill"
        name={t('common|Key generator')}
      >
        <FwButton
          primary
          responsive
          small
          leftIcon="RiKey2Fill"
          loading={loadingKey}
          onClick={onGenerateKey}
          type="button"
        >
          {t('common|Generate a key')}
        </FwButton>
        {generatedKey && <FwMessage>{generatedKey}</FwMessage>}
      </FwSegment>
    </FwGrid>
  );
};

About.propTypes = {};

export default About;
