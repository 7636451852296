import { Identity } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';

import { AdminStructure } from '..';

// see FasterWeb\Startup.cs if there are any changes in pattern
const identity0AS: AdminStructure<Identity> = [
  {
    key: 'username',
    name: 'Username',
    required: true,
    isReadOnly: true,
    type: FIELD_TYPE.text,
    pattern: '([\\w-._]*)*@[\\w-_]*\\.[\\w-._]*',
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'token',
    name: 'Password',
    required: true,
    type: FIELD_TYPE.password,
    pattern: '.{6,}',
    row: 2,
    column: 1,
    subInputs: [],
    visible: true,
  },
];

const identity1AS: AdminStructure<Identity> = [
  {
    key: 'roles',
    name: 'Roles',
    type: FIELD_TYPE.multiselect,
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
];

const identity1Rules = [
  {
    event: {
      eventID: 1,
      action: 'ONLOAD',
      type: 'FIELD',
      key: 'roles',
    },
    script: {
      scriptID: 1,
      description: 'Get options from assignation api',
      action: 'SEARCH',
      type: 'FIELD',
      data: "api({ local: true }).get(`assignation`).then(res => res && res.data.assignations && callback({ options: {'roles': res.data.assignations.map(a => ({ key: a.key, value: a.key, text: a.key, items: null })) } }))",
    },
  },
];

export { identity0AS, identity1AS, identity1Rules };
