import { FIELD_TYPE, OPERATOR } from 'core/utils/constant';

const colFilter = {
  key: 'ViewContentID',
  operatorNK: OPERATOR.equals,
  position: 0, // for filter purpose only
  type: FIELD_TYPE.text, // must be the type of the property
};

const row = {
  key: 'Row',
  name: 'Row',
  position: 1,
  type: FIELD_TYPE.number, // must be the type of the property
};

const column = {
  key: 'Column',
  name: 'Column',
  position: 2,
  type: FIELD_TYPE.number, // must be the type of the property
};

const name = {
  key: 'Field.Name',
  name: 'Name',
  position: 3,
  type: FIELD_TYPE.text, // must be the type of the property
};

const key = {
  key: 'Field.Key',
  name: 'Key',
  position: 4,
  type: FIELD_TYPE.text, // must be the type of the property
};

const fieldType = {
  key: 'Field.FieldTypeNK',
  name: 'FieldType',
  position: 4,
  type: FIELD_TYPE.text, // must be the type of the property
};

const table = {
  columns: [colFilter, row, column, name, key, fieldType],
  rowCount: 10,
  sortByColumnKey: 'Row',
  ascendingSort: true,
  additionalData: {
    disableViewed: true,
    disableDoubleClick: false,
    disableContext: true,
    disableCache: true,
  },
};

export default table;
