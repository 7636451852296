import _ from 'lodash';

import { AppPreference } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';

import { AdminStructure } from '..';

const appPreferenceAS: AdminStructure<AppPreference> = [
  {
    key: 'language',
    name: 'Language',
    type: FIELD_TYPE.select,
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
  //{
  //  position: 2,
  //  name: 'Default module',
  //  key: 'defaultModule',
  //  type: FIELD_TYPE.text,
  //},
  {
    key: 'smallMenu',
    name: 'Collapse menu',
    type: FIELD_TYPE.checkbox,
    row: 2,
    column: 1,
    additionalData: { noLabel: true },
    subInputs: [],
    visible: true,
  },
];

export { appPreferenceAS };
