import { FIELD_TYPE, OPERATOR } from 'core/utils/constant';

const colFilter = {
  key: 'UserID',
  operatorNK: OPERATOR.equals,
  position: 0, // for filter purpose only
  type: FIELD_TYPE.text, // must be the type of the property
};

const name = {
  key: 'Field.Name',
  name: 'Name',
  position: 1,
  type: FIELD_TYPE.text, // must be the type of the property
};

const operator = {
  key: 'OperatorNK',
  name: 'Operator',
  position: 2,
  type: FIELD_TYPE.text, // must be the type of the property
};

const value = {
  key: 'Value',
  name: 'Value',
  position: 3,
  type: FIELD_TYPE.text, // must be the type of the property
};

const editable = {
  key: 'Editable',
  name: 'Editable',
  position: 4,
  type: FIELD_TYPE.text, // must be the type of the property
};

const table = {
  columns: [colFilter, name, operator, value, editable],
  rowCount: 10,
  sortByColumnKey: 'Operator',
  ascendingSort: true,
  additionalData: {
    disableViewed: true,
    disableDoubleClick: false,
    disableContext: true,
    disableCache: true,
  },
};

export default table;
