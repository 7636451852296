import { TFunction } from 'i18next';

import { getMaskRowText } from '../FwMask.helpers';
import { MaskStructure } from '../FwMask.structures';

// todo wip#125 refactor
const formToCard = (maskStructure: MaskStructure, maskRow, t: TFunction) => {
  const { key, data: rowData, color } = maskRow;
  const title = getMaskRowText(maskStructure, rowData, t);

  return {
    title,
    key,
    color,
    row: maskRow,
  };
};

export { formToCard };
