import { bool, InferProps, string } from 'prop-types';
import React, { ChangeEventHandler, FC, useState } from 'react';

import { FwIframe } from 'components/base';
import utils from 'core/utils/utils';

import Base from '../base/FwInput.Base';
import { CommonInputProps } from '../FwInput';

const FwLinkInput: FC<Props & CommonInputProps> = ({
  // direct props
  collapsed = true,
  placeholder,
  // common input props
  defaultValue,
  value,
  clearable,
  disabled,
  readOnly,
  onChange,
  ...props
}) => {
  const defaultText = value || defaultValue;
  const isUpdatable = onChange || !value;

  const [text, setText] = useState(defaultText);
  const [showIframe, setShowIframe] = useState(!collapsed);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setText(e.target.value);
    onChange && onChange(e, utils.getNameValueFromEData(e));
  };

  const handleOpenInNewTab = () => {
    utils.openInNewTab(text);
  };

  const handleToggleShowIframe = () => {
    setShowIframe(!showIframe);
  };

  return (
    <>
      <Base
        clearable={clearable}
        disabled={disabled}
        leftIcon="RiExternalLinkFill"
        placeholder={placeholder}
        readOnly={readOnly}
        rightIcon={
          text ? (showIframe ? 'RiEyeOffFill' : 'RiEyeFill') : undefined
        }
        value={text}
        onChange={isUpdatable && handleInputChange}
        onLeftIconClick={handleOpenInNewTab}
        onRightIconClick={handleToggleShowIframe}
        {...props}
      />
      {text && showIframe && <FwIframe url={text} />}
    </>
  );
};

const propTypes = {
  collapsed: bool,
  placeholder: string,
};

export type Props = InferProps<typeof propTypes> & CommonInputProps;

FwLinkInput.propTypes = propTypes;

export default FwLinkInput;
